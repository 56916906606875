import { getSessionStorage, removeSessionStorage } from 'assets/constant';
import PrivateRoute from 'components/PrivateRoute';
import { Activity } from 'components/common/dashboard';
import { NotFound } from 'components/shared';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { jwtDecode } from 'jwt-decode';
import Haksa from 'pages/Haksa';
import { AdminDashBoard, LearningBoardAccount, UserAccounts } from 'pages/admin';
import { SubjectAdmin } from 'pages/admin/subject/SubjectAdmin';
import { AttendanceLayout } from 'pages/attendance/AttendanceLayout';
import { AttendanceTeacher } from 'pages/attendance/AttendanceTeacher';
import AdminSetting from 'pages/common/setting/AdminSetting';
import Setting from 'pages/common/setting/Setting';
import { Faq } from 'pages/common/supporting/faq/Faq';
import { FaqDetail } from 'pages/common/supporting/faq/FaqDetail';
import { FaqEdit } from 'pages/common/supporting/faq/FaqEdit';
import { FaqWrite } from 'pages/common/supporting/faq/FaqWrite';
import { Manual } from 'pages/common/supporting/manual/Manual';
import { Notice } from 'pages/common/supporting/notice/Notice';
import { NoticeDetail } from 'pages/common/supporting/notice/NoticeDetail';
import { NoticeEdit } from 'pages/common/supporting/notice/NoticeEdit';
import { NoticeWrite } from 'pages/common/supporting/notice/NoticeWrite';
import { Reference } from 'pages/common/supporting/reference/Reference';
import { ReferenceDetail } from 'pages/common/supporting/reference/ReferenceDetail';
import { ReferenceEdit } from 'pages/common/supporting/reference/ReferenceEdit';
import { ReferenceWrite } from 'pages/common/supporting/reference/ReferenceWrite';
import { Logout } from 'pages/logout';
import { QrLoginPage } from 'pages/qr';
import { Main } from 'pages/student/dashboard';
import { Report } from 'pages/student/report/Report';
import { ReportLayout } from 'pages/student/report/ReportLayout';
import { ReportStudentLayout } from 'pages/student/report/ReportStudentLayout';
import { ReportAdmin } from 'pages/student/report/admin/ReportAdmin';
import { ReportStudentListAdmin } from 'pages/student/report/admin/ReportStudentListAdmin';
import { Subject } from 'pages/student/subject';
import { Write } from 'pages/supporting';
import { MainTeacher } from 'pages/teacher/dashboard';
import { ReportStudentList } from 'pages/teacher/report/ReportStudentList';
import { ReportStudentListDetail } from 'pages/teacher/report/ReportStudentListDetail';
import { ReportTeacher } from 'pages/teacher/report/ReportTeacher';
import { SubjectDetailTeacher, SubjectTeacher } from 'pages/teacher/subject';
import { SubjectDetailVideoTeacher } from 'pages/teacher/subject/SubjectDetailVedioTeacher';
import { SubjectTeacherLayout } from 'pages/teacher/subject/SubjectTeacherLayout';
import React, { useLayoutEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { useUserState } from 'recoil/useUserState';
import { getCookie, removeCookies } from 'util/cookie';
import './App.css';
import './assets/css/color.css';
import './assets/css/font.css';
import './assets/css/reset.css';
import Login from './components/shared/Login';

function App() {
  const [user, setUser] = useRecoilState(useUserState);
  const [loginuser, setLoginUser] = useRecoilState(loginUser);
  const accessToken = getSessionStorage();
  const refreshToken = getCookie('refreshToken');

  useGetRequiredApi();

  useLayoutEffect(() => {
    const accessCookie = getCookie('YWNjZXNzdG9rZW4v');

    if (accessCookie) {
      setLoginUser(jwtDecode(accessCookie));
      return setUser(jwtDecode(accessCookie));
    }
    // 토큰이 둘중 하나라도 없으면 토큰 제거
    if (!accessToken) {
      removeSessionStorage('accessToken');
      removeCookies('removeToken');
      setLoginUser(null);
      return setUser(null);
    }

    // 토큰 타입 언디파인드 에러핸들링
    if (accessToken === 'undefined' || refreshToken === 'undefined') {
      removeSessionStorage('accessToken');
      removeCookies('removeToken');
      setLoginUser(null);
      return setUser(null);
    }

    if (accessToken) {
      try {
        setUser(jwtDecode(accessToken));
        setLoginUser(jwtDecode(accessToken));
        return;
      } catch (error) {
        removeSessionStorage('accessToken');
        removeCookies('removeToken');
        setLoginUser(null);
        return setUser(null);
      }
    }
  }, [accessToken, refreshToken]);

  return (
    <Router>
      {
        <Routes>
          {accessToken && refreshToken ? (
            <Route element={<PrivateRoute />}>
              {
                // 학생
                user?.role === 'STUDENT' && (
                  <>
                    <Route path='/' element={<Main />} />
                    <Route path='/activity' element={<Activity />} />
                    <Route path='/subject' element={<Subject />} />

                    <Route path='/report' element={<ReportLayout />}>
                      <Route path='' element={<Report />} />
                    </Route>

                    <Route path='/setting' element={<Setting />} />

                    <Route path='/*' element={<NotFound />} />

                    <>
                      {/* 지원센터 */}
                      <Route path='/notice' Main element={<Notice />} />
                      <Route path='/notice/detail/:noticeId' element={<NoticeDetail />} />

                      <Route path='/faq' element={<Faq />} />
                      <Route path='/faq/detail/:faqId' element={<FaqDetail />} />

                      <Route path='/reference' element={<Reference />} />
                      <Route path='/reference/detail/:referenceId' element={<ReferenceDetail />} />

                      <Route path='/manual' element={<Manual />} />
                      <Route path='/manual/write' element={<Write />} />
                    </>
                  </>
                )
              }

              {
                // 선생 라우트
                user?.role === 'TEACHER' && (
                  <>
                    <Route path='/' element={<MainTeacher />} />
                    <Route path='/activity' element={<Activity />} />

                    <Route path='/subject' element={<SubjectTeacherLayout />}>
                      <Route path='' element={<SubjectTeacher />} />
                      <Route path='detail' element={<SubjectDetailTeacher />} />
                      <Route path='detail/video' element={<SubjectDetailVideoTeacher />} />
                    </Route>

                    <Route path='/attendance' element={<AttendanceLayout />}>
                      <Route path='' element={<AttendanceTeacher />} />
                    </Route>

                    {/* department studentreport */}
                    <Route path='/department' element={<ReportStudentLayout />}>
                      <Route path='' element={<ReportTeacher />} />
                    </Route>

                    <Route path='/student' element={<ReportLayout />}>
                      <Route path='' element={<ReportStudentList />} />
                      <Route path=':userId' element={<ReportStudentListDetail />} />
                    </Route>

                    <Route path='/setting' element={<Setting />} />

                    <Route path='/*' element={<NotFound />} />

                    <>
                      {/* 지원센터 */}
                      <Route path='/notice' Main element={<Notice />} />
                      <Route path='/notice/detail/:noticeId' element={<NoticeDetail />} />

                      <Route path='/faq' element={<Faq />} />
                      <Route path='/faq/detail/:faqId' element={<FaqDetail />} />

                      <Route path='/reference' element={<Reference />} />
                      <Route path='/reference/detail/:referenceId' element={<ReferenceDetail />} />

                      <Route path='/manual' element={<Manual />} />
                      <Route path='/manual/write' element={<Write />} />
                    </>
                  </>
                )
              }

              {
                // 어드민 라우트
                user?.role === 'ADMIN' && (
                  <>
                    <Route path='/' element={<AdminDashBoard />} />
                    <Route path='/activity' element={<Activity />} />

                    <Route path='/subject' element={<SubjectTeacherLayout />}>
                      <Route path='' element={<SubjectAdmin />} />
                      <Route path='detail' element={<SubjectDetailTeacher />} />
                    </Route>

                    {/* <Route path='/zoom' element={<ZoomTeacherLayout />}>
                      <Route path='' element={<ZoomTeacher />} />
                      <Route path='detail' element={<ZoomDetail />} />
                      <Route path='detail/student/:meetingId' element={<ZoomItemDetailStudent />} />
                      <Route path='setting' element={<ZoomSetting />} />
                    </Route> */}

                    <Route path='/attendance' element={<AttendanceLayout />}>
                      <Route path='' element={<AttendanceTeacher />} />
                    </Route>

                    {/* department studentreport */}
                    <Route path='/department' element={<ReportLayout />}>
                      <Route path='' element={<ReportAdmin />} />
                    </Route>

                    <Route path='/student' element={<ReportStudentLayout />}>
                      <Route path='' element={<ReportStudentListAdmin />} />
                      <Route path=':userId' element={<ReportStudentListDetail />} />
                    </Route>

                    <Route path='/useraccounts' element={<UserAccounts />} />
                    <Route path='/useraccounts/setting/:id' element={<AdminSetting />} />
                    <Route path='/managementboard' element={<LearningBoardAccount />} />

                    <Route path='/setting' element={<Setting />} />

                    <Route path='/notice/write' element={<NoticeWrite />} />
                    <Route path='/notice/edit/:noticeId' element={<NoticeEdit />} />

                    <Route path='/faq/write' element={<FaqWrite />} />
                    <Route path='/faq/edit/:faqId' element={<FaqEdit />} />

                    <Route path='/reference/write' element={<ReferenceWrite />} />
                    <Route path='/reference/edit/:referenceId' element={<ReferenceEdit />} />

                    <Route path='/*' element={<NotFound />} />

                    <>
                      {/* 지원센터 */}
                      <Route path='/notice' Main element={<Notice />} />
                      <Route path='/notice/detail/:noticeId' element={<NoticeDetail />} />

                      <Route path='/faq' element={<Faq />} />
                      <Route path='/faq/detail/:faqId' element={<FaqDetail />} />

                      <Route path='/reference' element={<Reference />} />
                      <Route path='/reference/detail/:referenceId' element={<ReferenceDetail />} />

                      <Route path='/manual' element={<Manual />} />
                      <Route path='/manual/write' element={<Write />} />
                    </>
                  </>
                )
              }
            </Route>
          ) : (
            <Route path='/login' element={<Login />} />
          )}

          <Route path='/logout' element={<Logout />} />
          <Route path='/login' element={<Login />} />
          <Route path='/error' element={<NotFound />} />
          <Route path='/haksa' element={<Haksa />} />
          {/* <Route path='/qr/login' element={<QrLoginPage />} /> */}
          <Route path='/qr/login/CAREER_1' element={<QrLoginPage />} />
          <Route path='/qr/login/CAREER_2' element={<QrLoginPage />} />
          <Route path='/qr/login/CAREER_3' element={<QrLoginPage />} />
          {/* <Route path='/boot' element={<Boot />} /> */}
          <Route path='/*' element={<Login />} />
        </Routes>
      }
    </Router>
  );
}

export default React.memo(App);
